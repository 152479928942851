export const isHomeScreenPwa = () => {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia('(display-mode: standalone)').matches
  );
};

export const IS_SERVICE_WORKER = !!(
  typeof ServiceWorkerGlobalScope !== 'undefined' &&
  self instanceof ServiceWorkerGlobalScope
);

const parseCookie = () => {
  if (typeof document === 'undefined') return null;
  const out: { [key in string]?: string } = {};
  for (const parts of document.cookie.split(';')) {
    const [key, value] = parts.split('=', 2) as [string, string?];
    const cleanKey = key.trim();
    if (cleanKey && value != null) out[cleanKey] = value.trim();
  }
  return out;
};

const PLATFORMS = ['android', 'ios'] as const;
type Platform = typeof PLATFORMS[number];

type AppInfo = {
  platform: Platform;
  version: string | null;
};

// our iOS app is run in a webview. Our webview overrides the user agent string so we can detect it:
const nativeIosInfo = () => {
  if (typeof navigator === 'undefined') return null;

  const match = navigator.userAgent.match(/ PWAShellIos(?:\/(\d+\.\d+\.\d+))?/);
  if (!match) return null;

  return {
    platform: 'ios' as const,
    version: match[1] || null,
  };
};

const SEMVER_REGEX = /^\d+\.\d+\.\d+$/;

// our android app is run in a TWA (trusted web activity).
// the only way to detect a TWA is via a custom launch query param,
// which we parse on our backend and set a cookie.
// this is probably less reliable than the user agent string.
const nativeAppInfo = (): AppInfo | null => {
  if (typeof window === 'undefined') return null;

  const nativeRaw =
    parseCookie()?.native_wrap || window.sessionStorage.getItem('_nativeapp');
  if (!nativeRaw) return null;

  const [, platform, appVersion] =
    (nativeRaw?.match(/^(android|ios)--(\d+\.\d+\.\d+)$/) as [
      string?,
      Platform?,
      string?,
    ]) || [];

  if (!platform || !PLATFORMS.includes(platform)) return null;

  // also use sessionStorage just in case cookie gets cleared somehow.
  // TODO: check if this sessionStorage bleeds into the device's browser
  window.sessionStorage.setItem('_nativeapp', nativeRaw);

  return {
    platform,
    version: appVersion || null,
    // deviceId: TODO
  };
};

const serviceWorkerInfo = () => {
  const swScope = self as unknown as ServiceWorkerGlobalScope;

  // the url of the servive worker script e.g. /sw.js?app_platform=ios&app_version=1.0.0
  const params = new URLSearchParams(swScope.location?.search || '');
  const platform = params.get('app_platform') as Platform | null;
  const appVersion = params.get('app_version') || null;

  if (!platform || !PLATFORMS.includes(platform)) return null;

  return {
    platform,
    version: appVersion?.match(SEMVER_REGEX) ? appVersion : null,
  };
};

export const NATIVE_INFO: AppInfo | null = IS_SERVICE_WORKER
  ? serviceWorkerInfo()
  : typeof window !== 'undefined'
  ? nativeIosInfo() || nativeAppInfo()
  : null;
