import { browserEnv } from './env';

// release version at bundle time. UNUSED
// export const BUNDLED_RELEASE_VERSION = IS_DEV
//   ? 'development'
//   : process.env.HEROKU_SLUG_COMMIT || process.env.GITHUB_SHA || undefined;

// release version on page-load. We prefer using this instead of
// `BUNDLED_RELEASE_VERSION` because we can update this value during tests.
export const LOADED_RELEASE_VERSION = browserEnv('release-version');

let latestReleaseVersion: string | undefined;
export const setLatestReleaseVersion = (v: string) => {
  latestReleaseVersion = v;
};

export const isReleaseMismatch = () =>
  !!latestReleaseVersion &&
  !!LOADED_RELEASE_VERSION &&
  LOADED_RELEASE_VERSION !== latestReleaseVersion;
